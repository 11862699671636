import Button from '@/components/atoms/Button/Button';
import TextButton from '@/components/atoms/TextButton/TextButton';
import { ResponsiveMargin } from '@/components/atoms/legacy/ResponsiveMargin/ResponsiveMargin';
import { PropsWithChildren } from 'react';

interface ButtonProps {
  disabled?: boolean;
  onClick: () => void;
  ariaLabel: string;
  text: string;
}

interface LoginSignupTemplateProps {
  title: string;
  subtitle?: string;
  buttonPrompt?: ButtonProps;
  textPrompt?: ButtonProps;
  infoFooter?: React.ReactNode;
  errorMessage?: string;
}

const LoginSignupTemplate: React.FC<
  PropsWithChildren<LoginSignupTemplateProps>
> = ({
  title,
  subtitle,
  buttonPrompt,
  textPrompt,
  infoFooter,
  errorMessage,
  children,
}) => {
  return (
    <ResponsiveMargin>
      <div className="mx-auto flex max-w-[537px] flex-col gap-xl py-3xl m:gap-3xl">
        <div className="flex flex-col gap-s m:text-center">
          <h1 className="text-h3SemiBold text-brandMidnight m:text-h2SemiBold">
            {title}
          </h1>
          {subtitle && (
            <div className="text-body1Light text-neutral-900">{subtitle}</div>
          )}
        </div>
        <div className="flex flex-col items-center gap-l">
          <form
            onSubmit={(e) => e.preventDefault()}
            className="flex w-full flex-col gap-3xl"
          >
            {children}
            <div className="flex flex-col gap-s">
              {buttonPrompt && (
                <Button
                  type="submit"
                  onClick={buttonPrompt.onClick}
                  aria-label={buttonPrompt.ariaLabel}
                  disabled={buttonPrompt.disabled}
                >
                  {buttonPrompt.text}
                </Button>
              )}
              {errorMessage && (
                <div className="text-body2Regular text-red-dark">
                  {errorMessage}
                </div>
              )}
            </div>
          </form>

          {textPrompt && (
            <TextButton
              onClick={textPrompt.onClick}
              aria-label={textPrompt.ariaLabel}
            >
              {textPrompt.text}
            </TextButton>
          )}
        </div>
        {infoFooter && (
          <div className="mt-l rounded-xsmall bg-neutral-100 p-l text-body2Light text-neutral-800">
            {infoFooter}
          </div>
        )}
      </div>
    </ResponsiveMargin>
  );
};

export default LoginSignupTemplate;
