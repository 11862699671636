'use client';

import TextButton, {
  TextButtonVariants,
} from '@/components/atoms/TextButton/TextButton';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import { EnvelopeIcon } from '@heroicons/react/24/solid';
import { useRouter } from 'next/navigation';

const analytics = {
  login: GOOGLE_TRACK_INFO.emailLoginButton,
  signup: GOOGLE_TRACK_INFO.emailSignUpButton,
};

interface EmailFederationButtonProps {
  actionType?: 'login' | 'signup';
  sendAnalytics?: boolean;
  preHook?: () => void;
}

const EmailFederationButton: React.FC<EmailFederationButtonProps> = ({
  actionType = 'login',
  sendAnalytics,
  preHook,
}) => {
  const router = useRouter();

  return (
    <TextButton
      icon={{
        icon: <EnvelopeIcon className="size-xl" />,
        position: 'left',
      }}
      aria-label={'Continue with email'}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        preHook?.();
        router.push(
          actionType === 'signup' ? '/sign-up/email' : '/login/email'
        );
      }}
      variant={TextButtonVariants.Default}
      analyticsEvent={sendAnalytics ? analytics[actionType] : undefined}
    >
      Continue with email
    </TextButton>
  );
};

export default EmailFederationButton;
