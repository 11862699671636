import { SocialFederationOptions } from '@/components/molecules/SocialFederationOptions/SocialFederationOptions';
import LoginSignupTemplate from '@/components/template/LoginSignupTemplate/LoginSignupTemplate';
import { SEOMetaTags } from '@/lib/seo/seoTags';
import { BasePageProps } from '@/types/page';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';

export const LogInPage = () => {
  const router = useRouter();
  const redirectToPartnerFlowOnSignIn =
    router.query?.partner_redirect === 'true';

  return (
    <LoginSignupTemplate
      title={SEOMetaTags.loginPage.h1Tag}
      textPrompt={{
        ariaLabel: 'Create account',
        onClick: () =>
          router.push(
            `/sign-up${redirectToPartnerFlowOnSignIn ? '?partner_redirect=true' : ''}`
          ),
        text: 'Create a new account',
      }}
    >
      <SocialFederationOptions actionType="login" />
    </LoginSignupTemplate>
  );
};

export const getStaticProps: GetStaticProps<BasePageProps> = async ({
  locale,
}) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', ['common'])),
      noFooter: true,
      metadata: {
        title: SEOMetaTags.loginPage.title,
        description: SEOMetaTags.loginPage.description,
        keywords: SEOMetaTags.loginPage.keywords,
      },
    } satisfies BasePageProps,
  };
};

export default LogInPage;
